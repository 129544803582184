.JunBots-Headline {
    text-align: center;
    margin-bottom: 15px;
}

@media (max-width: 576px) {
    .JunBots-Headline {
        padding: 0;
    }
    .JunBots-Headline-Text {
        padding-top: 50px;
    }
}


.JunBots-JunBot-GeneralQuestion {
    margin-top: 20px;
    font-size: x-large;
    margin-bottom: 15px;
}

.JunBots-FloatingElement {
    border-radius: 10px;
}

.JunBots-JunBot-BackButton {
    text-align: left;
}

.JunBots-Middle {
    width: 100%;
}

.JunBot-Credits {
    margin-top: 103px;
    margin-bottom: 70px;
}