.ExpandableText-grid {
    display: grid;
    grid-template-columns: [first] min-content [middle] auto [end];
    grid-template-rows: auto auto;
    align-items: center;

    grid-auto-columns: inherit;
    grid-auto-rows: inherit;
}

.ExpandableText-ExpandButton {
    grid-column-start: first;
    grid-column-end: middle;
    grid-row: 1;
}

.ExpandableText-title-text {
    grid-column-start: middle;
    grid-column-end: end;
    grid-row: 1;
}

.ExpandableText-expandable-content {
    grid-column-start: middle;
    grid-column-end: end;
    grid-row: 2;
}