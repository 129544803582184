.JunBots-Disclaimer {
    /* margin-top: 10px; */
    margin-bottom: 15px;
    padding: 0;
    text-align: justify;
}


.JunBots-Disclaimer-Headline {
color: #6E1818;
}


