.Headline-Container {
    display: grid;
    grid-template-columns: [left-start] min-content [left-end mid-start] auto [mid-end right-start] min-content [right-end];
    grid-template-rows: [first-start] auto [first-end second-start] auto [second-end];

    grid-row-gap: 5px;

    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
}

.Headline-Title {
    grid-column: left-start / right-end;
    grid-row: first-start / first-end;

    justify-self: center;
    text-align: center;
}

@media (max-width: 576px) {
    .Headline-Title.sm {
        grid-row-start: second-start;
        hyphens: auto;
    }
}

@media (max-width: 768px) {
    .Headline-Title.md {
        grid-row-start: second-start;
        hyphens: auto;
    }
}

.Headline-left {
    grid-column-start: left-start;
    grid-column-end: left-end;
    grid-row: first-start / first-end;
}

.Headline-right {
    grid-column-start: right-start;
    grid-column-end: right-end;
    grid-row: first-start / first-end;
}