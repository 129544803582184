
.AdvancedRadioButton-Label {
    font-size: var(--radio-button-size);
}

.ExpandableText-RadioButton {
    padding-top: 0.7rem;
}


.ShowMoreLessText-LabelButton:hover,
.ShowMoreLessText-LabelButton {
    cursor: pointer;
    user-select: none;
}

.ShowMoreLessText-LabelButton {
    color: #0a53be;
}

.ShowMoreLessText-LabelButton:hover {
    color: #317dee;
}

.AdvancedRadioButton-RadioButton {
    width: var(--radio-button-size);
    height: var(--radio-button-size);
}

.AdvancedRadioButton {
    display: flex;
}

.AdvancedRadioButton-LeftColumn {
    margin-top: 0.11rem;
}

.AdvancedRadioButton-RightColumn {
    margin-left: 1rem;
}


@keyframes show {
    from {height: 0}
    to {height: auto}
}