:root {
    --font-size-feedback-header: 18pt;
}

.FeedbackToastContentContainer {
    padding: 6px;
    min-width: 300px;
}

.FeedbackToastCloseButton {
    display: flex;
    margin-left: auto;
    margin-top: 1pt;
}

.FeedbackToastCloseButton:hover {
    background-color: lightblue;
    border-radius: 50%;
}

.FeedbackHeader {
    display: flex;
    flex-grow: 1;
    margin-bottom: 12px;
}

.FeedbackHeader h4 {
    font-size: var(--font-size-feedback-header);
    font-weight: bold;
}

.FeedbackHeaderText {
    display: flex;
}

.FeedbackOptionEmojis {
    display: flex;
    justify-content: space-between;
}

.OptionEmojiRed {
    fill: #861e1e;
}

.OptionEmojiRed:hover {
    fill: #dc3545;
}

.OptionEmojiOrange {
    fill: #c96a01;
}

.OptionEmojiOrange:hover {
    fill: #ffa707;
}

.OptionEmojiGreen {
    fill: darkgreen;
}

.OptionEmojiGreen:hover {
    fill: #3ce13c;
}
