.JunBot-Footer-right {
    text-align: right;
    color: lightgray;
    margin-top: auto;
}

.Footer-Information {
    text-align: left;
    color: white;
}

.Jun-Logo {
    aspect-ratio: auto;
    height: 14pt;
    font-size: 14pt;
    font-weight: bold;
    color: #7e1718;
}

a.Footer-Information {
    color: white;
}

a:hover.Footer-Information {
    color: white;
    font-weight: bold;
}

@media (max-width: 575px) {
    .Footer-Information {
        margin-bottom: 0.5em;
    }

    .JunBot-Footer-right {
        text-align: left;
    }
}

@media (max-width: 767px) {
    .Jun-Logo {
        margin-bottom: 0.5em;
    }
}