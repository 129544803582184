.JunBots-SingleQuestion-Card {
    box-shadow: 5px 5px 10px #888888;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    max-width: 900px;
}

.JunBots-SingleQuestion-CardBody {
    padding-left: 0;
    padding-right: 0;
}

.JunBots-SingleQuestion-Content {
    text-align: left;
    max-width: 100%;
    padding: 0;
}

.JunBots-SingleQuestion-ExpandButton {
    padding-left: 0;
}

.JunBot-Card-Body {
    margin-bottom: 0.5em;
}

.JunBots-SingleQuestion-InputElement {
    grid-column-start: -1;
    margin-left: 20px;
}

@media (max-width: 576px) {
    .JunBots-SingleQuestion-InputElement {
        grid-column-start: 2;
        grid-row: 3;
        margin-left: 0;
        margin-top: 1.5rem;
    }
}